import type { PinPaymentConfig } from "ricecooker-types/pin"
import i18n from "@/i18n"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { dataHydration } from "@/services/DataHydrationService"
import { getLocalPrinterStorageKey } from "@/pages/settings/general/printers/receipt-layouts/utils/useLocalPrinters"
import { cloneDeep } from "lodash"
import { offlineModeStore } from "@/store/offlineMode"
import type { DeviceInformation } from "ricecooker-types/deviceinformation"

export const RiceCooker = {
    isPresent: (): boolean => {
        return Object.prototype.hasOwnProperty.call(window, "riceCooker")
    },
    // is it safe to close RC/logout?
    isSafeToTerminate: (): boolean => {
        return !RiceCooker.isPresent() || !offlineModeStore().isOffline
    },
    async syncFetch() {
        return window.riceCooker.syncData()
    },
    async syncRemove(args: { id: string | number }) {
        return window.riceCooker.syncRemove(args)
    },
    async dbFetchAll(table: string) {
        return window.riceCooker.dbFetchAll(table)
    },
    // TODO: check use for this with Rick
    async seqid() {
        return window.riceCooker.seqid()
    },
    async login(clientId: number) {
        if (!RiceCooker.isPresent()) {
            return
        }
        await window.riceCooker.loggedIn({
            clientId,
        })
    },
    async logout() {
        if (!RiceCooker.isPresent()) {
            return
        }
        await window.riceCooker.loggedOut()
    },
    async deviceInformation(): Promise<DeviceInformation | undefined> {
        if (
            RiceCooker.isPresent() &&
            typeof window.riceCooker.deviceInformation !== "undefined"
        ) {
            return await window.riceCooker.deviceInformation()
        }
        return undefined
    },
    payWithPin(config: PinPaymentConfig) {
        const { t: translate } = i18n.global
        if (!RiceCooker.isPresent()) {
            throw translate("pos_ticket_payment_downloadOb")
        }
        return window.riceCooker.pin(config)
    },
    async storeData(data: any) {
        if (!RiceCooker.isPresent()) {
            return
        }
        if (!dataHydration.rcTablesCreated) {
            setTimeout(() => this.storeData(data), 2000)
            return
        }
        // only do it when master or when it's a new order made by offline RC
        if (
            !useMasterSlave().isMaster.value &&
            (!Object.prototype.hasOwnProperty.call(data, "data") ||
                !Object.prototype.hasOwnProperty.call(data.data, "id") ||
                data.data.id.toString().indexOf("new") === -1)
        ) {
            return
        }
        // if meta._type, then push all that's in data
        // otherwise push data._type or data.*._type
        if (
            Object.prototype.hasOwnProperty.call(data, "meta") &&
            Object.prototype.hasOwnProperty.call(data.meta, "_type")
        ) {
            // if not paginating, then delete entire db
            if (!Object.prototype.hasOwnProperty.call(data.meta, "per_page")) {
                await window.riceCooker.purge([data.meta._type])
                await window.riceCooker.createTables()
            }
            await window.riceCooker.store(data.meta._type, data.data)
            return
        }
        // single item
        const detected = this.detectStoreType(data.data)
        if (detected) {
            await window.riceCooker.store(detected.type, [detected.data])
            return
        }
        // array of items
        if (Array.isArray(data.data)) {
            const types: { [key: string]: any } = {}
            for (let i = 0; i < data.data.length; i++) {
                const detected = this.detectStoreType(data.data[i])
                if (detected) {
                    if (
                        !Object.prototype.hasOwnProperty.call(
                            types,
                            detected.type
                        )
                    ) {
                        types[detected.type] = []
                    }
                    types[detected.type].push(detected.data)
                }
            }
            // loop through types and push them
            if (Object.keys(types).length) {
                for (const type in types) {
                    await window.riceCooker.store(type, types[type])
                }
            }
        }
    },
    detectStoreType(data: any): { type: string; data: any } | null {
        if (
            typeof data === "object" &&
            Object.prototype.hasOwnProperty.call(data, "_type")
        ) {
            return {
                type: data._type,
                data,
            }
        }
        return null
    },
    syncLocalStorage() {
        window.riceCooker
            .getLocalStorage(
                "https://www.orderbuddy.nl/foodticket/cgi/admin.cgi"
            )
            .then((v1LocalStorageObject: any) => {
                const keysMap: Record<string, string> = {
                    alt_adyen_pin_poiid: "adyen_pin_poiid_local",
                    kwebble_click2dial_ext_local: "clickToDialLocalExtension",
                }
                Object.keys(v1LocalStorageObject)
                    .filter((v1Key) => v1Key.indexOf("alt_printer") === 0)
                    .forEach((altPrinter) => {
                        const matchArray = altPrinter.match(/(\d+)/)
                        if (matchArray) {
                            keysMap[altPrinter] = getLocalPrinterStorageKey(
                                matchArray[0]
                            )
                        }
                    })
                Object.keys(keysMap).forEach((v1Key) => {
                    const value = v1LocalStorageObject[v1Key]
                    if (value) {
                        const v3Key = keysMap[v1Key]
                        localStorage.setItem(v3Key, value)
                    }
                })
                localStorage.setItem("localStorageSynced", "1")
                localStorage.setItem(
                    "localStorageLegacy",
                    JSON.stringify(v1LocalStorageObject)
                )
            })
    },
    openCustomerScreen() {
        if (
            !RiceCooker.isPresent() ||
            window.location.pathname.indexOf("customer-screen") > -1
        ) {
            return
        }
        window.riceCooker.openCustomerScreen(
            window.location.origin + "/customer-screen"
        )
    },
    updateCustomerScreen(data: any) {
        if (
            !RiceCooker.isPresent() ||
            typeof window.riceCooker.updateCustomerScreen === "undefined"
        ) {
            return
        }
        try {
            window.riceCooker.updateCustomerScreen(cloneDeep(data))
        } catch (e) {
            console.error(e)
        }
    },
    async fetch(url: string, options: string) {
        return await window.riceCooker.fetch(url, options)
    },
}
