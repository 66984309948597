import { ServerFileWithPreview } from "@/interface/components/FileBrowser"

export interface ReceiptLayout {
    id: number
    client_id: number
    name: string
    sizing: {
        paper_size: string
        width: string | null
        height: string | null
    }
    spacing: {
        margin: string[]
        padding: string[]
    }
    view_type: string
    printer: string
    is_deleted: number
    is_available: boolean
    in_use: Array<{
        type_of_print: string // Type of receipt, or default printer
        quantity: number
    }>
    email: string | null
    is_default: boolean
    font: {
        name: string
        size: string
        size_products: number
    }
    has_no_logo: boolean | null
    content: {
        after_address: {
            text: string | null
            is_centered: boolean | null
        }
        after_products: {
            text: string | null
            is_centered: boolean | null
        }
        end_text: {
            text: string | null
            is_centered: boolean | null
        }
        custom_template: string | null
    }
    discount_id: number
    show_customer_info: ShowCustomerInfo
    has_no_prices: boolean | null
    show_kitchen_title: boolean | null
    has_no_def_footer: boolean | null
    sort_products_by: string | null
    ref_number: {
        does_show: boolean | null
        is_large: boolean
    }
    remarks: {
        pos: string | null
        ob: string | null
    }
    gprs: string | null
    address: {
        is_not_shown: boolean
        is_centered: boolean | null
    }
    categories: {
        has_selection: boolean | null
        selection: []
    }
    custom_css: string | null
    image: false
    dpi: number
    show_category_titles: boolean
    mode: string | null
    logo: {
        is_centered: boolean | null
        image: ServerFileWithPreview | {} // empty object comes from the backend
    }
    printer_css_logo: ServerFileWithPreview | {} // empty object comes from the backend
    products: {
        has_selection: boolean | null
        selection: []
    }
    show_points: boolean | null
    extras_per_line: number
    print_rules: string | null
    print_table_order_date_update: string
    show_still_to_pay: boolean | null
}

export enum ShowCustomerInfo {
    No = 0,
    Yes = 1,
    OnlyDelivery = 2,
}
