<template>
    <div class="tab-content info" :ref="scrollAreaRef()">
        <div class="left">
            <div class="row items-center">
                <div class="row-label">{{ translate("name") }}:</div>
                <div class="value">
                    <div class="search-block">
                        <span class="text">
                            <template v-if="order.address.first_name">
                                {{ order.address.first_name }}
                                {{ order.address.last_name }}
                            </template>
                            <template v-else-if="order.address.last_name">
                                {{ order.address.last_name }}
                            </template>
                            <template v-else-if="order.address.company">
                                {{ order.address.company }}
                            </template>
                            <template v-if="order.is_new_customer">
                                ({{ translate("new_customer") }})
                            </template>
                            <template v-else>-</template>
                        </span>
                        <ButtonBase
                            v-if="order.customer_id || order.company"
                            type="secondary"
                            icon="search"
                            class="search"
                            @click="openCustomerPage"
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ translate("address") }}:</div>
                <div
                    class="value address"
                    @click="copyAddress()"
                    v-if="order.address"
                >
                    {{ order.address.street }}
                    {{ order.address.street_number }}
                    <br />
                    {{ order.address.zipcode }} {{ order.address.city }}
                </div>
                <div class="value" v-else>-</div>
            </div>
            <div class="row">
                <div class="row-label">{{ translate("phone") }}:</div>
                <div class="value phone">
                    <span class="phoneInfo">
                        {{ order.phone || "-" }}
                        <ButtonBase
                            v-if="order.phone && callsEnabled"
                            class="click2dial"
                            icon="phone"
                            type="primary"
                            @click="makeCall"
                        />
                    </span>
                    <div
                        class="value phone-code"
                        v-if="order.address.phone_code"
                    >
                        (code:
                        {{ order.address.phone_code }})
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ translate("email") }}:</div>
                <div class="value">{{ order.email || "-" }}</div>
            </div>
            <div class="row">
                <div class="row-label">
                    {{ translate("order_placed_with") }}:
                </div>
                <div class="value">
                    {{ order.source.name }}
                    <span v-if="order.source.id">({{ order.source.id }})</span>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ translate("order_placed_at") }}:</div>
                <div class="value">
                    {{ formatDateTime(order.created_at) }}
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ translate("order") }}:</div>
                <div class="value order-info">
                    <span>
                        {{ translate("total") }}
                        <b>{{ useFormatCurrency(order.total) }}&nbsp;</b>
                    </span>

                    <span v-if="order.delivery_costs">
                        {{ translate("delivery_costs") }}
                        <b
                            >{{
                                useFormatCurrency(order.delivery_costs)
                            }}&nbsp;</b
                        >
                    </span>

                    <div>
                        (<span v-if="order.payment?.method">
                            {{ order.payment.method_name }},
                        </span>
                        <span v-if="order.payment2?.method">
                            {{ order.payment2.method_name }},
                        </span>
                        <span v-if="order.payment?.status">
                            {{
                                isActuallyPaid(order) || isPinFinal(order)
                                    ? order.payment.status_name
                                    : translate("unpaid")
                            }} </span
                        >)

                        <span v-if="isCashOrder && order.payment.option">
                            - {{ translate("customer_pays_with") }}
                            <b>{{
                                order.payment.option === "exact"
                                    ? useFormatCurrency(order.total)
                                    : useFormatCurrency(
                                          Number(order.payment.option)
                                      )
                            }}</b>
                        </span>
                    </div>

                    <SelectComponent
                        v-if="paymentMethodsEditable"
                        name="payment-options"
                        :options="paymentMethodOptions"
                        :value="order.payment?.method"
                        @update:value="updatePayment"
                    />
                </div>
            </div>
            <div class="row" v-if="order.discount?.amount">
                <div class="row-label">{{ translate("discount") }}:</div>
                <div class="value">
                    {{ useFormatCurrency(order.discount.amount) }}
                </div>
            </div>
            <div class="row" v-if="order.discount?.code">
                <div class="row-label">{{ translate("promo_code") }}:</div>
                <div class="value">
                    {{ order.discount.code }}
                </div>
            </div>
            <div class="row" v-if="order.points.paid">
                <div class="row-label">{{ translate("points_paid") }}:</div>
                <div class="value">
                    {{ order.points.paid || 0 }}
                </div>
            </div>

            <div class="row" v-if="order.tip > 0">
                <div class="row-label">{{ translate("tip") }}:</div>
                <div class="value">
                    {{ order.tip ? useFormatCurrency(order.tip) : "-" }}
                </div>
            </div>
            <div class="row" v-if="order.customer && order.customer.notes">
                <div class="row-label">{{ translate("notes") }}:</div>
                <div class="value">{{ order.customer.notes }}</div>
            </div>
        </div>
        <div class="right">
            <div class="rows">
                <div class="row">
                    <div class="row-label">
                        {{ translate("requested_time") }}:
                    </div>
                    <div class="value">
                        <span v-if="order.time === 'asap'">
                            {{ translate("asap") }}
                        </span>
                        <span v-else>{{ order.time || "-" }}</span>
                    </div>
                </div>
                <div class="row items-center">
                    <div class="row-label">
                        {{ translate("estimated_time") }}:
                    </div>
                    <div class="value time-set">
                        <span>{{ timeSetLabel }}</span>
                        {{ distanceDurationLabel }}
                        <ButtonBase @click="toggleModal" type="warning">
                            {{ translate("change") }}
                        </ButtonBase>
                    </div>
                </div>
            </div>
            <div class="map" v-if="order.coordinates">
                <RoutePlannerMap
                    :origin="
                        multiLocation.isActive
                            ? multiLocation.location(order.client_id)
                                  ?.coordinates
                            : userStore.user.coordinates
                    "
                    :destination="order.coordinates"
                    :deliverer="order.deliverer"
                    :mode="settingStore.settings.google_directions_mode"
                />
            </div>
        </div>
    </div>

    <TimeModal
        :show="isModalOpened"
        @close="toggleModal"
        :order-time="order.time_set"
        @update:time="updateTime"
        :sms-disabled="!order.phone || smsDisabled"
        :email-disabled="!order.email"
    />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import TimeModal from "@/pages/orders/modals/time/TimeModal.vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { Order } from "@/interface/orders/Order"
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import { useSettingsStore } from "@/store/Settings"
import { useOrdersStore } from "@/store/Orders"
import { useClickToDial } from "@/utils/clickToDial/useClickToDial"
import { getPaymentMethodsEditable } from "@/pages/orders/utils/usePaymentMethods"
import { useOrderOptions } from "@/pages/archive/edit/utils/useOrderOptions"
import { storeToRefs } from "pinia"
import { TimeSetData } from "../../time/TimeData"
import { fromUTC } from "@/utils/useTimeShift"
import { convertSecondsToHMS, useDateFormatters } from "@/utils/useRefactorDate"
import { isActuallyPaid, isPinFinal } from "@/utils/useIsOrderPaid"
import RoutePlannerMap from "@/pages/orders/modals/order/tabs/RoutePlannerMap.vue"
import { useUserStore } from "@/store/User"
import { multiLocationStore } from "@/store/MultiLocation"

export default defineComponent({
    name: "CustomerTab",
    components: {
        ButtonBase,
        TimeModal,
        SelectComponent,
        RoutePlannerMap,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props, context) {
        const { t: translate, n: numberFormat } = useI18n()
        const router = useRouter()
        const ordersStore = useOrdersStore()
        const { settings } = storeToRefs(useSettingsStore())
        const isModalOpened = ref(!!ordersStore.showTimeImmediately)
        const { formatDateTime } = useDateFormatters()

        const paymentMethodsEditable = computed(() =>
            getPaymentMethodsEditable(props.order.payment.method)
        )

        // eslint-disable-next-line vue/no-setup-props-destructure
        const { paymentMethodOptions } = useOrderOptions(
            toRef(props, "order"),
            props.order.payment?.method || ""
        )

        const openCustomerPage = () => {
            if (props.order.customer_id) {
                router.push({
                    name: "customers/edit",
                    params: { id: props.order.customer_id },
                })
            } else {
                router.push({
                    name: "customers",
                    query: {
                        search:
                            props.order.customer?.email ||
                            props.order.customer?.phone ||
                            props.order.customer?.last_name,
                    },
                })
            }
            ordersStore.isShowingOrderId = null
        }

        const copyAddress = async () => {
            try {
                const address = `
                    ${props.order.address.street} ${props.order.address.street_number}
                    ${props.order.address.zipcode} ${props.order.address.city}
                `
                await navigator.clipboard.writeText(address)
            } catch (error) {
                // Clipboard write failed
                // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
            }
        }

        const timeSetLabel = computed(() => {
            if (props.order.time_set) {
                return props.order.time_set
            }

            return `${
                props.order.delivery === "deliver"
                    ? settings.value.delivery_min_time
                    : settings.value.pickup_min_time
            } ${translate("minutes")}`
        })

        const distanceDurationLabel = computed(() => {
            if (props.order.delivery === "deliver" && props.order.distance) {
                if (props.order.duration) {
                    return `(~${numberFormat(
                        props.order.distance,
                        "1-digit"
                    )} km / ${convertSecondsToHMS(props.order.duration)} min)`
                }
            }

            return ""
        })

        const smsDisabled = computed(
            () =>
                !Number(settings.value.sms_send) ||
                Boolean(Number(settings.value.sms_send_prohibited))
        )

        const toggleModal = () => {
            isModalOpened.value = !isModalOpened.value
        }

        const updatePayment = (method: string) => {
            context.emit("update:order", {
                ...props.order,
                payment: { ...props.order.payment, method: method },
            })
        }

        const updateTime = (timeData: TimeSetData) => {
            context.emit("update:order", timeData)
        }

        const { callsEnabled, dial } = useClickToDial()

        const makeCall = async () => {
            dial(props.order.phone)
        }

        const isActuallyUnpaid = computed(() =>
            ["cash", "account"].includes(props.order.payment.method)
        )

        const isCashOrder = computed(
            () => props.order.payment.method === "cash"
        )

        return {
            translate,
            useFormatCurrency,
            openCustomerPage,
            copyAddress,
            timeSetLabel,
            distanceDurationLabel,
            toggleModal,
            isModalOpened,
            paymentMethodsEditable,
            paymentMethodOptions,
            updatePayment,
            updateTime,
            callsEnabled,
            makeCall,
            smsDisabled,
            fromUTC,
            isActuallyUnpaid,
            isCashOrder,
            convertSecondsToHMS,
            isActuallyPaid,
            isPinFinal,
            formatDateTime,
            userStore: useUserStore(),
            settingStore: useSettingsStore(),
            multiLocation: multiLocationStore(),
        }
    },
})
</script>

<style lang="scss" scoped>
.info {
    display: flex;
    column-gap: $margin;
    padding-right: $padding-m;

    .left,
    .right {
        width: 50%;
        row-gap: $margin;
        display: flex;
        flex-direction: column;
    }

    .left {
        row-gap: $margin-l;
        .search-block {
            display: inline-flex;
            column-gap: $padding-m;
            align-items: center;

            .text {
                text-decoration: underline;
            }

            .search {
                padding: 0 $padding-s;
            }
        }

        .phone {
            display: flex;
            flex-flow: column nowrap;
            gap: $margin-s;
            .phoneInfo {
                display: flex;
                align-items: center;
                gap: $margin;
            }

            .button {
                width: 2rem;
                height: 2rem;
            }
        }

        .address {
            cursor: copy;
        }

        .order-info {
            row-gap: $margin-s;
            display: grid;

            :deep(.input-field) {
                margin: 0;
            }
        }
    }

    .row {
        display: flex;
        column-gap: $margin-s;
        word-break: break-all;

        .row-label {
            width: 35%;
            color: $darker-gray;
            font-weight: $font-weight-medium;
        }

        .value {
            flex-basis: 0;
            flex-grow: 1;
        }
    }

    .right {
        background: $lighter-gray;
        border-radius: $radius;

        .rows {
            display: flex;
            flex-direction: column;
            padding: $padding $padding 0 $padding;
            row-gap: $margin;
        }

        .time-set {
            display: flex;
            column-gap: $margin-s;
            align-items: center;
            justify-content: space-between;
        }

        .map {
            height: 100%;

            .order-map {
                height: 100%;
                border-radius: 0 0 $radius $radius;
            }
        }
    }
}
</style>
