<template>
    <div class="status-panel" @click.stop>
        <h5>
            {{ userStore.user.address.company }}
            <span>#{{ userStore.user.id }}</span>
        </h5>
        <hr />

        <p class="no-flex">
            <b>API:</b><br />
            <span>{{ api }}</span>
        </p>
        <p>
            <b>Client time:</b>
            <span>{{ time }}</span>
        </p>
        <p>
            <b>OBID:</b>
            <span>{{ obId }}</span>
        </p>
        <p>
            <b>WS:</b>
            <span>{{ websocketStatus }} / {{ websocketSubscription }}</span>
        </p>
        <p>
            <b>UI version: </b>
            <span>{{ uiVersion }}</span>
        </p>
        <p>
            <b>Mode:</b>
            <span>{{ mode }}</span>
        </p>
        <p>
            <b>DH:</b>
            <span>{{ dataHydrationStatus }}</span>
        </p>
        <p>
            <b>DH Progress:</b>
            <span>{{ dataHydrationProgress }}</span>
        </p>
        <p>
            <b>Public internet:</b>
            <span>{{ internetStatus }}</span>
        </p>
        <p v-if="userStore.user.is_admin">
            <b>Admin</b>
            <span>{{ userStore.user.is_admin }}</span>
        </p>

        <template v-if="RiceCooker.isPresent()">
            <hr />
            <p>
                <b>RC version:</b>
                <span>{{ riceCookerVersion }}</span>
            </p>
            <p>
                <b>Unsynced:</b>
                <span>{{ unsyncedOfflineOrders }}</span>
            </p>
            <p>
                <b>Internal IP:</b>
                <span>{{ internalIp }}</span>
            </p>
            <br />
            <ButtonBase
                @click="goToDebug()"
                class="m-0"
                style="width: 100%"
                variant="outlined"
                icon="eye-glasses"
                >Debug</ButtonBase
            >
            <ButtonBase
                class="m-0 mt-2"
                style="width: 100%"
                variant="outlined"
                @click="toggleOnlineOffline"
                >Toggle online/offline</ButtonBase
            >
            <ButtonBase
                class="m-0 mt-2"
                style="width: 100%"
                variant="outlined"
                @click="updatesStore().doUpdateChecks()"
                :loading="updatesStore().doingUpdateChecks"
            >
                Force update check
            </ButtonBase>
        </template>

        <ButtonBase
            :v-if="Boolean(Number(useSettingsStore().settings.masterslave))"
            :class="{
                'm-0': true,
                'mt-2': RiceCooker.isPresent(),
            }"
            :loading="useUserStore().togglingMasterSlave"
            style="width: 100%"
            variant="outlined"
            @click="toggleMasterSlave"
            >Toggle master/slave</ButtonBase
        >
        <ButtonBase
            v-if="dataHydration.isActive()"
            @click="dataHydration.hydrateAll(false, true)"
            variant="outlined"
            style="width: 100%"
            class="mt-2"
            :loading="isHydrating"
        >
            Toggle DataHydration
        </ButtonBase>
        <ButtonBase
            v-if="hasVoip"
            @click="subscribeToCallerIdEvents(true)"
            variant="outlined"
            style="width: 100%"
            class="mt-2"
        >
            Subscribe to CallerID (Status:
            {{ callerIdActive ? "Connected" : "Disconnected" }})
        </ButtonBase>
        <ButtonBase
            v-if="showImpersonationButton"
            @click="adminImpersonation"
            variant="outlined"
            style="width: 100%"
            class="mt-2"
        >
            Toggle admin ({{
                translate(userStore.user.is_admin ? "active" : "inactive")
            }})
        </ButtonBase>
    </div>
</template>

<script lang="ts">
import * as Sentry from "@sentry/vue"
import { defineComponent, onUnmounted, ref, computed } from "vue"
import { useUserStore } from "@/store/User"
import { RiceCooker } from "@/riceCooker/riceCooker"
import axios from "axios"
import { websocket } from "@/services/WebsocketService"
import { dataHydration } from "@/services/DataHydrationService"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { useSettingsStore } from "@/store/Settings"
import { useCallerId } from "@/utils/useCallerId"
import {
    devGetFrontendVersion,
    devGetRiceCookerVersion,
} from "@/utils/devUtils"
import { offlineModeStore } from "@/store/offlineMode"
import { updatesStore } from "@/store/Updates"

export default defineComponent({
    name: "StatusPanel",
    methods: { useSettingsStore, useUserStore, updatesStore },
    components: { ButtonBase },
    props: {},
    setup(props, { emit }) {
        const userStore = useUserStore()
        const { t: translate } = useI18n()
        const time = ref("")
        const obId = ref("Unknown")
        const websocketStatus = ref("Unknown")
        const websocketSubscription = ref("Unknown")
        const dataHydrationStatus = ref("Unknown")
        const dataHydrationProgress = computed(() => dataHydration.status)
        const internetStatus = ref("Unknown")
        const internalIp = ref("Unknown")
        const mode = ref("Browser: online")
        const riceCookerVersion = ref("Not enabled")
        const api = ref(axios.defaults.baseURL)
        const uiVersion = devGetFrontendVersion()
        const unsyncedOfflineOrders = ref("0 (OK)")

        const update = () => {
            const masterSlave = useMasterSlave()
            api.value = axios.defaults.baseURL
            time.value = new Date().toLocaleString()
            obId.value =
                masterSlave.obId +
                " (" +
                (masterSlave.isMaster.value ? "Master" : "Slave") +
                ")"

            if (RiceCooker.isPresent()) {
                ;(async () => {
                    mode.value = "RC: " + (await window.riceCooker.getMode())
                    riceCookerVersion.value =
                        (await devGetRiceCookerVersion()) || "Unknown"
                    internalIp.value = await window.riceCooker.getIpAddress()
                })()
            }

            websocketStatus.value = websocket.getConnectionState()
            websocketSubscription.value = websocket.subscribed
                ? "Subscribed"
                : "Not subscribed!"
            dataHydrationStatus.value = dataHydration.isActive()
                ? "Active (" +
                  (Math.floor(new Date().getTime() / 1000) -
                      dataHydration.lastActivity) +
                  "s)"
                : "Inactive"
            internetStatus.value = navigator.onLine
                ? "Connected"
                : "Disconnected"

            if (RiceCooker.isPresent()) {
                RiceCooker.syncFetch()
                    .then((data) => {
                        unsyncedOfflineOrders.value =
                            (data.max > 0 ? "warning" : "ok") +
                            " (" +
                            data.max +
                            ")"
                    })
                    .catch()
            }
        }
        const interval = setInterval(update, 500)
        update()

        onUnmounted(() => {
            clearInterval(interval)
        })

        const router = useRouter()
        const goToDebug = () => {
            emit("closeStatusPanel")
            router.push("/debug")
        }

        const toggleMasterSlave = async () => {
            useUserStore().togglingMasterSlave = true
            const isMaster = useMasterSlave().isMaster.value
            if (!isMaster && useSettingsStore().settings.master_ob_id) {
                useUserStore().confirm_overwrite_master = true
            } else {
                try {
                    await useMasterSlave().setMaster(!isMaster)
                    await dataHydration.hydrateModule("settings", true)
                } catch (e) {
                    Sentry.captureException(e)
                } finally {
                    useUserStore().togglingMasterSlave = false
                }
            }
        }

        const toggleOnlineOffline = async () => {
            if (!offlineModeStore().isOffline) {
                await offlineModeStore().setOffline()
            } else {
                await offlineModeStore().setOnline()
            }
        }

        const showImpersonationButton = process.env.VUE_APP_ENV !== "production"
        const adminImpersonation = () => {
            userStore.admin_info_modal_open = false
            axios.post("/utils/development/impersonation/toggle").then(() => {
                userStore.fetchUser()
            })
        }

        const hasVoip = useSettingsStore().settings.voip_provider
        const { subscribeToCallerIdEvents, VoipStore } = useCallerId()

        return {
            userStore,
            translate,
            obId,
            time,
            api,
            mode,
            uiVersion,
            RiceCooker,
            riceCookerVersion,
            websocketStatus,
            websocketSubscription,
            dataHydrationStatus,
            internetStatus,
            unsyncedOfflineOrders,
            internalIp,
            toggleMasterSlave,
            toggleOnlineOffline,
            adminImpersonation,
            showImpersonationButton,
            goToDebug,
            dataHydration,
            dataHydrationProgress,
            isHydrating: dataHydration.isHydratingAllModules,
            hasVoip,
            subscribeToCallerIdEvents,
            callerIdActive: VoipStore.subscribed,
        }
    },
    emits: ["closeStatusPanel"],
})
</script>

<style lang="scss" scoped>
.status-panel {
    cursor: initial;
    position: fixed;
    background: $white;
    width: 17rem;
    margin-top: $margin;
    left: $margin-s;
    padding: $padding;
    border: $thick-important-border;
    border-radius: $radius;
    display: flex;
    flex-flow: column nowrap;
    z-index: 20;

    &:before {
        content: " ";
        background: inherit;
        border-color: inherit;
        border-width: 2px 0 0 2px;
        width: 1.2rem;
        height: 1.2rem;
        transform: rotate(45deg);
        top: -0.7rem;
        left: 1.6rem;
        position: absolute;
    }

    h5 > span {
        color: $dark-gray;
    }

    hr {
        margin-top: $margin-xs;
        margin-bottom: $margin-xs;
    }
    p {
        &:not(.no-flex) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        b {
            font-weight: $font-weight-medium;
        }
        font-size: $font-size-s;
        span {
            text-transform: uppercase;
            font-size: $font-size-s;
        }
        margin-bottom: $margin-xs;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
