// TODO: split up into frontend and riceCooker updates stores
import { defineStore } from "pinia"
import axios from "axios"
import {
    devGetFrontendVersion,
    devGetRiceCookerVersion,
} from "@/utils/devUtils"
import { useUserStore } from "@/store/User"
import { nextTick, Ref } from "vue"
import { useIdle, UseIdleReturn, useTimestamp } from "@vueuse/core"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { offlineModeStore } from "@/store/offlineMode"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import i18n from "@/i18n"
import { gtmSendCustomEvent } from "@/utils/gtmSendEvent"

const { t: translate } = i18n.global
const defaultRiceCookerAutomaticUpdateTimerSeconds = 899 // 15 minutes (minus 1 second) before RiceCooker is automatically updated when the update modal is open
const updateCheckIntervalSeconds = 15 * 60 // 15 minutes in between update checks
const userIsIdleTimeSeconds = 5 * 60 // 5 minutes is when a user becomes inactive

export const riceCookerPostponeUpdateOptions = [
    {
        label: translate("remind_me_later"),
        value: null,
        disabled: true,
    },
    {
        label: `5 ${translate("minutes").toLowerCase()}`,
        value: 5,
    },
    {
        label: `30 ${translate("minutes").toLowerCase()}`,
        value: 30,
    },
    {
        label: `1 ${translate("hour").toLowerCase()}`,
        value: 60,
    },
    {
        label: `4 ${translate("hours").toLowerCase()}`,
        value: 240,
    },
]

export interface FrontendTarget {
    version: string
    date: string
    url: string
    size: number
}

export interface RiceCookerAvailableVersions {
    [key: string]: string
}

export interface UpdatesStoreState {
    initiated: boolean
    idle: UseIdleReturn
    now: Ref<number>
    doingUpdateChecks: boolean
    frontendCurrentVersion: string | null
    frontendTarget: FrontendTarget
    riceCookerIsUpdating: boolean
    riceCookerAutomaticUpdateInterval:
        | ReturnType<typeof setInterval>
        | undefined
    riceCookerAutomaticUpdateTimer: number
    riceCookerUpdateModalIsVisible: boolean
    riceCookerUpdateErrorIsVisible: boolean
    riceCookerPostponeUpdateValue: number | null
    riceCookerCurrentVersion: string | null
    riceCookerTargetVersion: string | null
    riceCookerAvailableVersions: RiceCookerAvailableVersions
}

export const updatesStore = defineStore("updatesStore", {
    state: (): UpdatesStoreState => <UpdatesStoreState>({
            initiated: false,
            idle: useIdle(5000),
            now: useTimestamp({ interval: 1000 }),
            doingUpdateChecks: false,
            frontendCurrentVersion: null,
            frontendTarget: {
                version: "",
                date: "",
                url: "",
                size: 0,
            },
            riceCookerIsUpdating: false,
            riceCookerAutomaticUpdateInterval: undefined,
            riceCookerAutomaticUpdateTimer:
                defaultRiceCookerAutomaticUpdateTimerSeconds,
            riceCookerUpdateModalIsVisible: false,
            riceCookerUpdateErrorIsVisible: false,
            riceCookerPostponeUpdateValue: null,
            riceCookerCurrentVersion: null,
            riceCookerTargetVersion: null,
            riceCookerAvailableVersions: {},
        } as UpdatesStoreState),
    getters: {
        userIsIdle: (state) => {
            // If the user has been idle for more than 5 minutes
            return state?.now && state?.idle.lastActive
                ? Math.floor((state.now - state.idle.lastActive) / 1000) >
                      userIsIdleTimeSeconds
                : false
        },
        hasFrontEndUpdate: (state) => {
            return (
                state.frontendCurrentVersion !== null &&
                !offlineModeStore().isOffline &&
                state.frontendCurrentVersion !== "developer" &&
                state.frontendTarget.version !== null &&
                state.frontendCurrentVersion !== state.frontendTarget.version
            )
        },
        hasRiceCookerUpdate: (state) => {
            return (
                RiceCooker.isPresent() &&
                !offlineModeStore().isOffline &&
                state.riceCookerCurrentVersion !== null &&
                state.riceCookerTargetVersion !== null &&
                state.riceCookerCurrentVersion !== state.riceCookerTargetVersion
            )
        },
    },
    actions: {
        async initUpdatesStore() {
            if (!this.initiated) {
                await this.getAndSetCurrentVersions()
                await this.doUpdateChecks()
                setInterval(async () => {
                    await this.doUpdateChecks()
                }, 1000 * updateCheckIntervalSeconds)
                this.initiated = true
            }
        },
        async doUpdateChecks() {
            try {
                this.doingUpdateChecks = true
                await this.getAndSetFrontendTargetVersion()
                await this.getAndSetRiceCookerTargetVersion()
            } finally {
                this.doingUpdateChecks = false
            }
        },
        setRiceCookerUpdateModalIsVisible(show: boolean) {
            if (this.riceCookerUpdateModalIsVisible !== show) {
                this.riceCookerUpdateModalIsVisible = show
                if (show) {
                    this.startRiceCookerAutomaticUpdateInterval()
                } else {
                    this.stopRiceCookerAutomaticUpdateInterval()
                }
            }
        },
        async getAndSetCurrentVersions() {
            this.frontendCurrentVersion = devGetFrontendVersion()
            this.riceCookerCurrentVersion = await devGetRiceCookerVersion()
        },
        async getAndSetFrontendTargetVersion() {
            const response = await axios.get(
                process.env.VUE_APP_API_HOST +
                    "/v1/rice-cooker/releases/front-end"
            )
            if (response?.data?.meta?.latest) {
                this.frontendTarget = response.data.meta.latest
                if (this.hasFrontEndUpdate && this.userIsIdle) {
                    await this.applyFrontendUpdate()
                } else {
                    return true
                }
            } else {
                return new Error(
                    "There was an error getting the latest Frontend version from the API"
                )
            }
        },
        async getAndSetRiceCookerTargetVersion() {
            if (RiceCooker.isPresent() && !offlineModeStore().isOffline) {
                const response = await axios.get(
                    process.env.VUE_APP_API_HOST +
                        "/v1/rice-cooker/releases/app"
                )
                if (
                    response?.data?.data?.desired &&
                    response?.data?.data?.versions
                ) {
                    this.riceCookerTargetVersion = response?.data?.data?.desired
                    this.riceCookerAvailableVersions =
                        response?.data?.data?.versions
                    await nextTick()
                    if (this.hasRiceCookerUpdate) {
                        this.setRiceCookerUpdateModalIsVisible(true)
                    } else {
                        return true
                    }
                } else {
                    return new Error(
                        "There was an error getting the latest RiceCooker version from the API"
                    )
                }
            } else {
                return true
            }
        },
        startRiceCookerAutomaticUpdateInterval() {
            this.riceCookerAutomaticUpdateInterval = setInterval(async () => {
                if (this.riceCookerAutomaticUpdateTimer === 0) {
                    this.stopRiceCookerAutomaticUpdateInterval()
                    gtmSendCustomEvent(
                        "RiceCookerUpdateModal",
                        "applyUpdateAutomatically"
                    )
                    await updatesStore().applyRiceCookerUpdate(false)
                } else {
                    this.riceCookerAutomaticUpdateTimer--
                }
            }, 1000)
        },
        stopRiceCookerAutomaticUpdateInterval() {
            clearInterval(this.riceCookerAutomaticUpdateInterval)
            this.riceCookerAutomaticUpdateTimer =
                defaultRiceCookerAutomaticUpdateTimerSeconds
        },
        postponeRiceCookerUpdate(minutes: number, sendEvent: boolean = true) {
            if (!updatesStore().riceCookerIsUpdating) {
                this.riceCookerPostponeUpdateValue = minutes
                this.setRiceCookerUpdateModalIsVisible(false)
                if (sendEvent) {
                    gtmSendCustomEvent(
                        "RiceCookerUpdateModal",
                        "postponeUpdate",
                        minutes.toString()
                    )
                }
                setToastNotification(
                    "RiceCooker update postponed",
                    "RiceCooker update postponed for " +
                        riceCookerPostponeUpdateOptions.find(
                            (option) => option.value === minutes
                        )?.label,
                    "success"
                )
                setTimeout(() => {
                    this.setRiceCookerUpdateModalIsVisible(true)
                }, minutes * 60 * 1000)
            }
        },
        async applyFrontendUpdate(url: string = window.location.href) {
            if (!offlineModeStore().isOffline) {
                if (
                    this.hasFrontEndUpdate &&
                    this.frontendTarget.version !== ""
                ) {
                    window.location.href =
                        url + "?version=" + this.frontendTarget.version
                }
            }
        },
        async applyRiceCookerUpdate(sendEvent: boolean = true) {
            if (RiceCooker.isPresent() && this.hasRiceCookerUpdate) {
                if (sendEvent) {
                    gtmSendCustomEvent("RiceCookerUpdateModal", "applyUpdate")
                }
                this.riceCookerUpdateErrorIsVisible = false
                this.riceCookerIsUpdating = true
                if (
                    this?.riceCookerTargetVersion &&
                    Object.keys(this?.riceCookerAvailableVersions).length &&
                    this?.riceCookerAvailableVersions[
                        this.riceCookerTargetVersion
                    ]
                ) {
                    const targetVersion =
                        this.riceCookerAvailableVersions[
                            this.riceCookerTargetVersion
                        ]
                    const check = await window.riceCooker.update(
                        targetVersion,
                        "check"
                    )
                    if (
                        check.has_update &&
                        !check.is_downloading &&
                        !check.is_downloaded
                    ) {
                        await window.riceCooker.update(
                            targetVersion,
                            "download"
                        )
                        await window.riceCooker.update(targetVersion, "install")
                    }
                } else {
                    this.riceCookerUpdateErrorIsVisible = true
                    throw new Error(
                        "The target RiceCooker version is not available"
                    )
                }
                this.riceCookerIsUpdating = false
            }
        },
    },
})
