import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e25383c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deliverers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DelivererTile = _resolveComponent("DelivererTile")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tab-content deliverer-tab",
    ref: _ctx.scrollAreaRef()
  }, [
    (_ctx.selectedDeliverer)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["deliverers", {
                separated:
                    _ctx.delivererStore.activeDeliverers.length ||
                    _ctx.delivererStore.inactiveDeliverers.length,
            }])
        }, [
          _createVNode(_component_DelivererTile, {
            deliverer: _ctx.selectedDeliverer,
            selected: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectDeliverer(null)))
          }, null, 8, ["deliverer"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["deliverers", { separated: _ctx.delivererStore.inactiveDeliverers.length }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.delivererStore.activeDeliverers, (deliverer) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: deliverer.id
        }, [
          (deliverer.id !== _ctx.order.deliverer_id)
            ? (_openBlock(), _createBlock(_component_DelivererTile, {
                key: 0,
                deliverer: deliverer,
                onClick: ($event: any) => (_ctx.selectDeliverer(deliverer.id))
              }, null, 8, ["deliverer", "onClick"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.delivererStore.inactiveDeliverers, (deliverer) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: deliverer.id
        }, [
          (deliverer.id !== _ctx.order.deliverer_id)
            ? (_openBlock(), _createBlock(_component_DelivererTile, {
                key: 0,
                deliverer: deliverer,
                onClick: ($event: any) => (_ctx.selectDeliverer(deliverer.id))
              }, null, 8, ["deliverer", "onClick"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ], 512))
}