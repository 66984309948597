import { ClosingEntry, useClosingHoursStore } from "@/store/ClosingHours"
import { useOpeningHoursStore } from "@/store/OpeningHours"
import { DateTime, Interval } from "luxon"
import { flatten } from "lodash"
import { useSettingsStore } from "@/store/Settings"
import { DispatchType } from "@/interface/Cart"
import { useI18n } from "vue-i18n"
import { SelectOption } from "@/ui-elements/input/select/SelectComponent.vue"
import { getCustomTimeSlots } from "@/pages/pos/modal/customer/data/customTimeSlots"
import { generateDateRange } from "@/utils/useDateRanges"

export const useTimeIntervals = () => {
    const openingHoursStore = useOpeningHoursStore()
    const closingHoursStore = useClosingHoursStore()
    const settingsStore = useSettingsStore()
    const { t: translate } = useI18n()
    const defaultTimeStep = "15"

    const getTimeSteps = (steps: string[]) => {
        for (const step of steps) {
            if (step && step !== "0" && step !== "00" && Number(step) >= 5) {
                return step
            }
        }

        return defaultTimeStep
    }

    const getTimeIntervalsForFullDay = (
        type: DispatchType,
        date: string | null = null
    ) => {
        const dateTime = date ? DateTime.fromISO(date) : DateTime.now()
        const deliveryType = type === "deliver" ? "delivery" : "pickup"
        let timeSteps
        const options: SelectOption | Array<Object> = []
        const intervals = []

        if (deliveryType === "delivery") {
            timeSteps = getTimeSteps([
                settingsStore.settings.pos_delivery_time_steps,
                settingsStore.settings.pos_time_steps,
                settingsStore.settings.webshop_delivery_time_steps,
                settingsStore.settings.webshop_time_steps,
            ])
        } else {
            timeSteps = getTimeSteps([
                settingsStore.settings.pos_pickup_time_steps,
                settingsStore.settings.pos_time_steps,
                settingsStore.settings.webshop_pickup_time_steps,
                settingsStore.settings.webshop_time_steps,
            ])
        }

        let startTime = dateTime.startOf("day")
        const endTime = dateTime.startOf("day").plus({ days: 1 })

        while (startTime < endTime) {
            const endTimeInterval = startTime.plus({
                minutes: Number(timeSteps),
            })
            intervals.push(endTimeInterval)
            startTime = endTimeInterval
        }

        const intervalOptions = intervals.map(
            //@ts-ignore
            (interval: Interval) => {
                const formattedInterval = interval.toFormat("HH:mm")
                return {
                    label: formattedInterval,
                    value: formattedInterval,
                }
            }
        )

        options.push(...intervalOptions)

        // make sure 00:00 is first
        // @ts-ignore
        options.unshift(options.pop())

        return { timeSlots: options, additionalCosts: {} }
    }

    const getTimeIntervals = (
        type: DispatchType,
        date: string | null = null,
        hidePastSlotsForToday: boolean = false
    ) => {
        // use today's date if passed date is empty
        const currentDateTime =
            date !== null ? DateTime.fromISO(date) : DateTime.now()
        const deliveryType = type === "deliver" ? "delivery" : "pickup"

        // if custom settings are set then use them to generate custom time slots
        const customTimeSettings =
            settingsStore.settings[`dunkin_time_ranges_${deliveryType}`] ||
            settingsStore.settings.dunkin_time_ranges ||
            false

        if (customTimeSettings) {
            return getCustomTimeSlots(customTimeSettings, currentDateTime)
        }

        const currentWeekDay = currentDateTime.weekday
        let timeSteps = ""
        let initialTimeMinutes

        if (deliveryType === "delivery") {
            timeSteps = getTimeSteps([
                settingsStore.settings.pos_delivery_time_steps,
                settingsStore.settings.pos_time_steps,
                settingsStore.settings.webshop_delivery_time_steps,
                settingsStore.settings.webshop_time_steps,
            ])

            initialTimeMinutes =
                Number(settingsStore.settings.delivery_init_time) || 0
        } else {
            timeSteps = getTimeSteps([
                settingsStore.settings.pos_pickup_time_steps,
                settingsStore.settings.pos_time_steps,
                settingsStore.settings.webshop_pickup_time_steps,
                settingsStore.settings.webshop_time_steps,
            ])

            initialTimeMinutes =
                Number(settingsStore.settings.pickup_init_time) || 0
        }

        // opening hours are set for every week day and delivery type separately
        let openingHours = openingHoursStore.openingHours.find(
            (item) =>
                item.is_open &&
                item.day === currentWeekDay &&
                item.type === deliveryType
        )

        //@ts-ignore
        openingHours = openingHours
            ? openingHours
            : {
                  is_open: false,
                  from: currentDateTime.toISODate(),
                  till: currentDateTime.toISODate(),
                  day: currentWeekDay,
                  type: deliveryType,
                  closed_from: null,
                  closed_till: null,
              }

        if (!openingHours || !openingHours.from) {
            return { timeSlots: [], additionalCosts: {} }
        }

        const openingHoursFrom = DateTime.fromISO(openingHours.from).set({
            day: currentDateTime.day,
            month: currentDateTime.month,
            year: currentDateTime.year,
        })

        const openingHoursTill = currentDateTime.plus({ days: 1 }).set({
            hour: 5,
            minute: 59,
            second: 59,
        })

        let openedInterval = Interval.fromDateTimes(
            openingHoursFrom,
            openingHoursTill
        )

        // closed_from and closed_till fields can contain hours of break in working hours
        let breakInterval: Interval | null = null

        if (openingHours.closed_from && openingHours.closed_till) {
            let breakStartTime = DateTime.fromISO(openingHours.closed_from).set(
                {
                    day: openedInterval.start?.day,
                    month: openedInterval.start?.month,
                    year: openedInterval.start?.year,
                }
            )
            if (breakStartTime.hour < 6) {
                breakStartTime = breakStartTime.plus({ days: 1 })
            }

            let breakEndTime = DateTime.fromISO(openingHours.closed_till).set({
                day: openedInterval.start?.day,
                month: openedInterval.start?.month,
                year: openedInterval.start?.year,
            })

            if (breakEndTime.hour < 6) {
                breakEndTime = breakEndTime.plus({ days: 1 })
            }
            breakInterval = Interval.fromDateTimes(breakStartTime, breakEndTime)
        }

        // we use extra hours intervals to add them to original openedInterval
        const extraHoursIntervals: Interval[] = []
        let closedHoursInterval: Interval | null = null

        closingHoursStore.closingHours.forEach((closingHoursItem) => {
            const dateRange: Array<string> = generateDateRange(
                String(closingHoursItem.from.date),
                String(closingHoursItem.till.date)
            )

            if (!dateRange.includes(currentDateTime.toFormat("yyyy-MM-dd"))) {
                return
            }

            const interval = getClosingTimeInterval(
                closingHoursItem,
                openedInterval
            )

            if (!interval) {
                return
            }

            if (closingHoursItem.hours === 2) {
                // extra hours defined for this day
                extraHoursIntervals.push(interval)
            } else if (closingHoursItem.hours == 1) {
                // opening hours are changed
                openedInterval = interval
            } else {
                // restaurant is closed whole day
                closedHoursInterval = interval
            }
        })

        if (closedHoursInterval) {
            return {
                timeSlots: [
                    {
                        value: "",
                        label: translate("closed"),
                        disabled: true,
                    },
                ],
                additionalCosts: {},
            }
        }

        // delete break hours and add extra hours to opened hours
        let openedHoursIntervals: Interval[] = []
        if (breakInterval) {
            openedHoursIntervals = openedInterval.difference(breakInterval)
            openedHoursIntervals = Interval.merge([
                ...openedHoursIntervals,
                ...extraHoursIntervals,
            ])
        } else {
            openedHoursIntervals = Interval.merge([
                openedInterval,
                ...extraHoursIntervals,
            ])
        }

        if (!openedHoursIntervals.length) {
            return { timeSlots: [], additionalCosts: {} }
        }

        // if selected date is 'today' we cut timeSlots in the past, it's needed for the POS
        let startIntervalStartTime = openedHoursIntervals[0].start
        let startIntervalEndTime = openedHoursIntervals[0].start?.plus({
            minutes: initialTimeMinutes,
        })

        if (
            hidePastSlotsForToday &&
            currentDateTime.toISODate() === DateTime.local().toISODate() &&
            //@ts-ignore
            currentDateTime > openedHoursIntervals[0].start
        ) {
            //@ts-ignore
            startIntervalStartTime = currentDateTime.set({
                hour: 6,
                minute: 0,
                second: 0,
            })

            //@ts-ignore
            startIntervalEndTime = currentDateTime.plus({
                minutes: initialTimeMinutes,
            })
        }

        // we need to delete initialTimeMinutes from the intervals
        // because this is the minimum time for delivery/pickup that should not be available
        const initialTimeInterval = Interval.fromDateTimes(
            //@ts-ignore
            startIntervalStartTime,
            startIntervalEndTime
        )

        const openedHoursStartIntervals =
            openedHoursIntervals[0].difference(initialTimeInterval)

        openedHoursIntervals.splice(0, 1)
        // if something is left from the start intervals we need to replace them in the array of all intervals

        if (openedHoursStartIntervals.length) {
            openedHoursIntervals = Interval.merge([
                ...openedHoursStartIntervals,
                ...openedHoursIntervals,
            ])
        }

        const timeStepsIntervals = openedHoursIntervals.map(
            (openedHoursInterval) =>
                openedHoursInterval.splitBy({
                    minute: Number(timeSteps),
                })
        )

        // flatten time steps intervals because they were made for every openedHoursInterval separately
        const allOpenIntervals = flatten(timeStepsIntervals)

        const options: SelectOption[] = []

        allOpenIntervals.forEach((interval, index) => {
            //@ts-ignore
            const timeIntervalValue = interval.start
                //@ts-ignore
                .set({ minute: Math.round(interval.start.minute / 5) * 5 })
                .toFormat("HH:mm")
            options.push({
                value: timeIntervalValue,
                label: timeIntervalValue,
            })

            if (breakInterval && allOpenIntervals[index + 1]) {
                // check if breakInterval is between the current and the next intervals
                if (
                    //@ts-ignore
                    breakInterval.isAfter(interval.start) &&
                    //@ts-ignore
                    breakInterval.isBefore(allOpenIntervals[index + 1]?.end)
                ) {
                    const breakTime = breakInterval.toFormat("HH:mm")
                    options.push({
                        value: breakTime,
                        label: translate("closed") + " " + breakTime,
                        disabled: true,
                    })
                    // clear breakInterval so the check will not be run again
                    breakInterval = null
                }
            }
        })

        return { timeSlots: options, additionalCosts: {} }
    }

    const getClosingTimeInterval = (
        closingHoursItem: ClosingEntry,
        openedInterval: Interval
    ) => {
        const closingHoursBaseInterval =
            getClosingTimeBaseInterval(closingHoursItem)

        if (
            !closingHoursBaseInterval ||
            (!closingHoursBaseInterval.start &&
                !closingHoursBaseInterval.end) ||
            (!openedInterval.start && !openedInterval.end)
        ) {
            return null
        }

        // change date to the dates from openedInterval to check if closingHoursBaseInterval can be applied to the openedInterval
        const closingHoursStart = closingHoursBaseInterval.start.set({
            year: openedInterval.start?.year,
            month: openedInterval.start?.month,
            day: openedInterval.start?.day,
        })
        const closingHoursEnd = closingHoursBaseInterval.end.set({
            year: openedInterval.end?.year,
            month: openedInterval.end?.month,
            day: openedInterval.end?.day,
        })

        const closingHoursInterval = Interval.fromDateTimes(
            closingHoursStart,
            closingHoursEnd
        )

        if (closingHoursBaseInterval.overlaps(closingHoursInterval)) {
            return closingHoursInterval
        }

        return null
    }

    const getClosingTimeBaseInterval = (closingHoursItem: ClosingEntry) => {
        if (!closingHoursItem.from.date && !closingHoursItem.till.date) {
            return null
        }
        let closingStart: DateTime = DateTime.now().minus({
            day: 1,
        })
        let closingEnd: DateTime = DateTime.now().plus({
            day: 1,
        })
        if (closingHoursItem.from.date) {
            let hour = 0
            let minute = 0
            if (closingHoursItem.from.time) {
                const timeStart = DateTime.fromISO(closingHoursItem.from.time)
                hour = timeStart.hour
                minute = timeStart.minute
            }

            closingStart = DateTime.fromISO(closingHoursItem.from.date).set({
                hour: hour,
                minute: minute,
            })
        }
        if (closingHoursItem.till.date) {
            let hour = 23
            let minute = 59
            if (closingHoursItem.till.time) {
                const timeEnd = DateTime.fromISO(closingHoursItem.till.time)
                hour = timeEnd.hour
                minute = timeEnd.minute
            }

            closingEnd = DateTime.fromISO(closingHoursItem.till.date).set({
                hour: hour,
                minute: minute,
            })
        }

        return Interval.fromDateTimes(closingStart, closingEnd)
    }

    return {
        getTimeIntervals,
        getTimeIntervalsForFullDay,
    }
}
