import { Order } from "@/interface/orders/Order"
import {
    ReceiptLayout,
    ShowCustomerInfo,
} from "@/interface/settings/printers/Receiptlayout"
import {
    PrintableObject,
    locationSettings,
} from "@/services/printer/PrinterService"
import { useSettingsStore } from "@/store/Settings"
import { useUserStore } from "@/store/User"
import { getFileName, saveBlob } from "@/utils/useDownload"
import axios, { AxiosResponse } from "axios"
import { cloneDeep } from "lodash"
import { getHtml } from "@food-ticket/printhtml/src"

export const receiptLayout: ReceiptLayout = {
    id: 26085,
    client_id: 3,
    name: "InvoiceReceipt",
    sizing: { paper_size: "a4", width: null, height: null },
    spacing: {
        margin: ["10", "10", "10", "10"],
        padding: ["0", "0", "0", "0"],
    },
    view_type: "normal",
    printer: "Default printer",
    is_deleted: 0,
    is_available: true,
    email: null,
    is_default: false,
    font: { name: "Verdana, Geneva, sans-serif", size: "18", size_products: 0 },
    has_no_logo: true,
    content: {
        after_address: { text: null, is_centered: false },
        after_products: { text: null, is_centered: false },
        end_text: { text: null, is_centered: false },
        custom_template: null,
    },
    discount_id: -1,
    show_customer_info: ShowCustomerInfo.Yes,
    has_no_prices: false,
    show_kitchen_title: false,
    has_no_def_footer: false,
    sort_products_by: null,
    ref_number: { does_show: true, is_large: false },
    remarks: { pos: null, ob: null },
    gprs: null,
    address: { is_not_shown: false, is_centered: false },
    categories: { has_selection: false, selection: [] },
    custom_css: null,
    image: false,
    dpi: 0,
    show_category_titles: true,
    mode: "vector",
    logo: { is_centered: false, image: {} },
    printer_css_logo: {},
    products: { has_selection: false, selection: [] },
    show_points: null,
    extras_per_line: 1,
    print_rules: null,
    in_use: [],
    print_table_order_date_update: "0",
    show_still_to_pay: true,
}
const getReceiptHtml = (order: Order): string | string[] | undefined => {
    const { settings } = useSettingsStore()
    const printable: PrintableObject = cloneDeep({
        order,
        settings: receiptLayout,
        client: useUserStore().user,
        currency: settings.ob_currency,
    })

    const location: {
        address: {
            company: string
            contact: string
            address: string
            zipcode: string
            city: string
            state?: string | null
            country: string
            phone: string
            email: string
        }
        preferred_domain: string | null
        vat: string | null
    } = locationSettings(printable, order.client_id)

    return getHtml(
        printable.order,
        printable.settings,
        printable.discount,
        {
            currency: printable.currency,
            print_labels_separate: Boolean(
                Number(settings.print_labels_separate)
            ),
        },
        location.address,
        location.vat,
        location.preferred_domain
    )
}
export const receiptToBackendAndSendInvoice = (order: Order): void => {
    const html: string | string[] | undefined = getReceiptHtml(order)

    if (html === undefined) {
        return
    }

    axios
        .post("/client/orders/" + order.id + "/send-invoice", {
            receipt: html,
        })
        .catch((): void => {
            console.warn("Couldn't send receipt to the backend.")
        })
}
export const getReceiptPDFFromBackend = (order: Order, name: string) => {
    const html: string | string[] | undefined = getReceiptHtml(order)
    if (html === undefined) {
        return
    }

    return axios
        .post(
            "/utils/generatePdf",
            {
                dataHtml: html,
                fileName: name,
            },
            {
                responseType: "blob",
            }
        )
        .then((response: AxiosResponse<any>): void => {
            saveBlob(response.data, getFileName(response))
        })
}
