import { Order } from "@/interface/orders/Order"
import {
    SplitPaymentPerson,
    SplitPaymentProduct,
    SplitPaymentSplitType,
    SplitPaymentStep,
} from "@/interface/pos/SplitPaymentPerson"
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"
import { printOrder } from "@/services/printer/PrinterService"
import { finishPlacingOrder, openCashDrawer } from "@/store/cart/cartOrderUtils"
import { usePinPaymentStore } from "@/store/PinPayment"
import { usePOSStore } from "@/store/POS"
import { useSettingsStore } from "@/store/Settings"
import {
    divideAmountOverPayers,
    generateRandomString,
    round as calculateRound,
} from "@/utils/calculations"
import { cents } from "@/utils/math/Currency"
import * as Sentry from "@sentry/vue"
import axios, { AxiosResponse } from "axios"
import { cloneDeep, round } from "lodash"
import { defineStore } from "pinia"
import { translate } from "@food-ticket/printhtml/src/lang"
import { translations } from "@food-ticket/printhtml/src/lang/en"

type PaymentInput = {
    payment_method: string
    type: string
    amount: { amount: number; currency: string }
    rounding_difference?: { amount: number; currency: string }
    transaction_id?: string // Order id
    service_id?: string | null // Client id + unique OrderBuddy id
    poiid?: string | null
    sale_id?: string // Unique
    is_final_payment: boolean
    tip?: { amount: number; currency: string }
}

export type Balance = {
    paid: { amount: number; currency: string }
    remaining: { amount: number; currency: string }
    total: { amount: number; currency: string }
}

export type SplitPaymentState = {
    step: SplitPaymentStep
    splitType: SplitPaymentSplitType
    personsCount: number
    persons: SplitPaymentPerson[]
    modalProducts: SplitPaymentProduct[]
    selectedPersonIndex: number
    orderId: number
    order: Order | null
    activePaymentIndex: number
    roundingDifference: number
    balance: Balance
    isPinPaymentLoading: boolean
    loading: boolean
}

export const useSplitPaymentStore = defineStore({
    id: "splitPayment",
    state: () => getInitialSplitPaymentStore() as SplitPaymentState,
    getters: {
        selectedPerson: (state) => state.persons[state.selectedPersonIndex],
        isPartiallyPaid: (state): boolean =>
            // The payment has started
            (state.balance.paid?.amount ?? 0) > 0 &&
            (state.balance.remaining?.amount ?? 0) > 0,
        numberOfPeopleToPay: (state): number =>
            state.persons.filter((person: SplitPaymentPerson) => !person.paid)
                .length,
        lastPersonIsPaying: (state): boolean =>
            state.persons.filter((person: SplitPaymentPerson) => !person.paid)
                .length === 1,
        finalPersonPaid: (state): boolean =>
            state.persons.filter((person: SplitPaymentPerson) => !person.paid)
                .length === 0,
        splitTypePersonOrProducts: (state): string =>
            state.splitType === SplitPaymentSplitType.Amount
                ? "person"
                : "product",
        orderTotal: (state): number => state.order?.total ?? 0,
        isPaid: (state): boolean => (state.balance.remaining?.amount ?? 0) <= 0,
        // The total is either what the person paid, or is going to pay. The subtotal is the original not-rounded value,
        // which in cash payments can be different
        totalProjectedPayment:
            (state) =>
            (onlyRemainingPayers: boolean = false): number => {
                let persons: SplitPaymentPerson[] = state.persons
                if (onlyRemainingPayers) {
                    persons = persons.filter(
                        (person: SplitPaymentPerson) => !person.paid
                    )
                }

                return round(
                    persons.reduce(
                        (total: number, person: SplitPaymentPerson) =>
                            total + person.subtotal,
                        0
                    ),
                    2
                )
            },
        // Tip is calculated over the amounts of people that haven't paid yet. It's actually the virtual tip.
        // It's negative when people pay less than was projected.
        tip: (state): number =>
            state.persons.reduce(
                (tip: number, person: SplitPaymentPerson): number =>
                    tip + person.tip,
                0
            ),

        remainingTotalToAssign: (state): number => {
            const totalAssigned: number = state.persons.reduce(
                (
                    sum: number,
                    person: SplitPaymentPerson,
                    index: number
                ): number => {
                    if (state.selectedPersonIndex === index || person.paid) {
                        return sum
                    }
                    return sum + person.total
                },
                0
            )

            const remainingAmount: number = cents(
                state.balance.remaining.amount - totalAssigned
            )
            return remainingAmount >= 0 ? remainingAmount : 0
        },
    },
    actions: {
        async initialize(order: Order) {
            this.order = cloneDeep(order)
            await this.fetchBalance()
            this.createOrUpdatePersons()
        },
        async fetchBalance(): Promise<void> {
            if (!this.order?.id) {
                return
            }

            const response: AxiosResponse<any> = await axios.get(
                `/client/orders/${this.order.id}/payment/`
            )

            this.balance = response.data.data.balance
        },
        async changeNumberOfPersons(addendum: -1 | 1) {
            this.personsCount += addendum

            this.createOrUpdatePersons()

            // It can happen that after removing a person, the order is fully paid,
            // in that case we need to finish it all up
            if (this.finalPersonPaid) {
                await this.markOrderAsPaid()
            }
        },
        closeProductsModal() {
            const posStore = usePOSStore()
            posStore.setCartOverlay(true)
            posStore.openedModal = ""
            this.selectedPersonIndex = -1
            this.modalProducts = []
        },
        clearState() {
            this.$state = getInitialSplitPaymentStore()
            this.closeOverlays()
        },
        closeOverlays() {
            const posStore = usePOSStore()
            posStore.openedModal = null
            posStore.posComponent = "POSMenu"
            posStore.setToolsOverlay(false)
            posStore.setCartOverlay(false)
        },
        showOverlays() {
            const posStore = usePOSStore()
            posStore.openedModal = ""
            posStore.setToolsOverlay(true)
            posStore.setCartOverlay(true)
        },
        // 1st step of payment
        async pay(index: number, method: PaymentMethod): Promise<void> {
            this.loading = true
            this.persons[index].paymentError = undefined
            this.activePaymentIndex = index

            this.persons[index].paymentMethod = method

            const roundedAmount: number = calculateRound(
                this.persons[index].total,
                2,
                20
            )

            if (method === PaymentMethod.PIN) {
                // If the received amount was not updated, we send the original total, which is the unrounded value
                let pinAmount: number = 0
                if (
                    this.persons[index].received ===
                        this.persons[index].total ||
                    this.persons[index].received === roundedAmount
                ) {
                    pinAmount = this.persons[index].total
                } else {
                    pinAmount = this.persons[index].received
                    this.persons[index].tip = round(
                        this.persons[index].received -
                            this.persons[index].total,
                        2
                    )
                }

                try {
                    const pinPayment: {
                        is_successful: boolean
                        reference: string | undefined
                    } = await this.sendPayment(
                        pinAmount,
                        method,
                        0,
                        this.persons[index].tip
                    )

                    if (
                        pinPayment.is_successful &&
                        (pinPayment.reference ||
                            useSettingsStore().settings.adyen_pin_active ===
                                "0")
                    ) {
                        this.persons[index].pinPayment.reference =
                            pinPayment.reference ?? null
                        this.persons[index].pinPayment.is_successful =
                            pinPayment.is_successful
                    } else {
                        this.activePaymentIndex = -1
                    }
                } catch (e: any) {
                    Sentry.captureException(e)
                    this.activePaymentIndex = -1
                    this.persons[index].paymentError = e.message || e

                    this.loading = false
                    return
                }
            } else {
                // @todo, what is this line doing? It seems the subtotal and total have been already set before this point and they're equal
                this.persons[index].subtotal = this.persons[index].total
                let roundingDifference: number = round(
                    roundedAmount - this.persons[index].total,
                    2
                )

                this.persons[index].total = roundedAmount
                // If the received amount is equal to the rounded amount, then we don't consider this tip,
                // but a rounding difference
                if (
                    this.persons[index].received === this.persons[index].total
                ) {
                    this.roundingDifference += roundingDifference
                    this.persons[index].tip = 0
                } else {
                    // ... conversely, if there's tip, we don't remove the rounding difference, it's inside
                    // the tip
                    this.persons[index].tip = round(
                        this.persons[index].received -
                            this.persons[index].subtotal,
                        2
                    )
                    roundingDifference = 0
                }

                await this.sendPayment(
                    this.persons[index].received,
                    method,
                    roundingDifference,
                    this.persons[index].tip
                )
            }

            await this.markPersonAsPaid(index)
            this.adjustLastPerson()
            if (this.finalPersonPaid) {
                await this.markOrderAsPaid()
            }
            if (this.order) {
                await openCashDrawer(this.order)
            }

            this.activePaymentIndex = -1
            this.loading = false
        },
        // 3rd step of payment
        markPersonAsPaid(index: number): void {
            this.persons[index].paid = true
            this.persons[index].paymentError = ""
        },
        // 4th last step of payment
        async markOrderAsPaid(): Promise<any> {
            if (!this.order?.id) {
                return
            }

            try {
                await finishPlacingOrder(this.order)
                await printOrder(this.order)
                this.clearState() // Return to POS
                usePinPaymentStore().resetPaymentStatuses()
                usePinPaymentStore().clearPayment()
            } catch (e) {
                Sentry.captureException(e)
                console.log(e)
            }
        },
        // Send the payment to the backend and update the balance
        async sendPayment(
            amount: number,
            method: PaymentMethod,
            roundingDifference?: number,
            tip?: number
        ): Promise<{ is_successful: boolean; reference: string | undefined }> {
            if (!this.order) {
                return { is_successful: false, reference: "Missing order!" }
            }

            let data: PaymentInput = {
                payment_method: method.valueOf(),
                type: this.splitTypePersonOrProducts,
                amount: {
                    amount: round(amount, 2),
                    currency: useSettingsStore().settings.ob_currency,
                },
                is_final_payment: this.lastPersonIsPaying,
            }

            if (tip) {
                data = {
                    ...data,
                    ...{
                        tip: {
                            amount: tip ?? 0,
                            currency: useSettingsStore().settings.ob_currency,
                        },
                    },
                }
            }

            if (method === PaymentMethod.CASH && roundingDifference != 0.0) {
                data = {
                    ...data,
                    ...{
                        rounding_difference: {
                            amount: roundingDifference ?? 0,
                            currency: useSettingsStore().settings.ob_currency,
                        },
                    },
                }
            } else if (
                method === PaymentMethod.PIN &&
                useSettingsStore().settings.adyen_pin_active
            ) {
                usePinPaymentStore().initiatePayment(this.order.id.toString())
                this.isPinPaymentLoading = true

                data = { ...data, ...usePinPaymentStore().paymentInput }
            }

            console.log(
                "SentryDevInfo: SplitPinPayment.ts POST client/orders/orderid/payment/pay",
                data
            )

            let result: {
                is_successful: boolean
                reference: string | undefined
            } = {
                is_successful: false,
                reference: "",
            }

            // @ts-ignore
            const response: AxiosResponse<any> = await axios
                .post(`client/orders/${this.order?.id}/payment/`, data, {
                    // Need 2 minutes for Adyen to handle the request
                    timeout: method === PaymentMethod.PIN ? 1000 * (60 * 2) : 0,
                })
                .catch((e): void => {
                    Sentry.captureException(e)
                    console.log(e.message)
                    result.is_successful = false
                })
                .finally((): void => {
                    if (method === PaymentMethod.PIN) {
                        usePinPaymentStore().isLoading = false
                        usePinPaymentStore().pinState = ""
                    }
                })

            if (
                method === PaymentMethod.PIN &&
                useSettingsStore().settings.adyen_pin_active === "1"
            ) {
                try {
                    result = await usePinPaymentStore().unpackAdyenResponse(
                        response.data.data.message,
                        this.order
                    )
                    if (result.is_successful) {
                        usePinPaymentStore().success = true
                        setTimeout((): void => {
                            usePinPaymentStore().resetPaymentStatuses()
                            usePinPaymentStore().clearPayment()
                        }, 1000)
                    }
                } catch (e: any) {
                    Sentry.captureException(e)
                    const errorContent =
                        e.message ||
                        e ||
                        translate("unknown_error" as keyof typeof translations)
                    await usePinPaymentStore().abort()
                    throw new Error(errorContent)
                } finally {
                    this.isPinPaymentLoading = false
                }
            } else {
                result.is_successful = true
            }

            this.setBalance(response.data.data.balance)

            return result
        },
        adjustLastPerson() {
            if (!this.lastPersonIsPaying) {
                return
            }

            this.createOrUpdatePersons()
        },
        // @todo, move this function, it's not an action
        // Split the remaining amount over the remaining people that haven't paid and haven't received an amount yet
        splitEqually(
            withExistingCustomers: boolean = false
        ): SplitPaymentPerson[] {
            const splittablePersons: SplitPaymentPerson[] = this.persons.filter(
                (person: SplitPaymentPerson): boolean =>
                    !person.paid && !person.set
            )

            const { personTotal, lastPersonTotal } = divideAmountOverPayers(
                withExistingCustomers
                    ? splittablePersons.length
                    : this.personsCount,
                // The tip that was given by people that already paid, is added by the backend to the total order
                // amount. So we don't have to add it here.
                this.balance.remaining.amount
            ) as { personTotal: number; lastPersonTotal: number }

            const persons: SplitPaymentPerson[] = cloneDeep(this.persons)

            // @todo, see if we can move next part, would make it easier to understand
            if (!withExistingCustomers) {
                const splitPaymentPersons: SplitPaymentPerson[] = []
                for (let i: number = 0; i < this.personsCount; i++) {
                    const total: number =
                        i === this.personsCount - 1
                            ? lastPersonTotal
                            : personTotal
                    splitPaymentPersons.push({
                        identifier: generateRandomString(7),
                        set: false,
                        paid: false,
                        subtotal: total,
                        total: total,
                        discount: 0,
                        deposit: 0,
                        tip: 0,
                        received: calculateRound(total, 2, 20),
                        productsCount: 0,
                        products: [],
                        pinPayment: {
                            reference: null,
                            is_successful: false,
                        },
                    })
                }

                return splitPaymentPersons
            }

            splittablePersons.forEach(
                (person: SplitPaymentPerson, index: number): void => {
                    const personIndex: number = persons.findIndex(
                        (originalPerson: SplitPaymentPerson): boolean =>
                            originalPerson.identifier === person.identifier
                    )

                    if (persons[personIndex]) {
                        if (
                            index === splittablePersons.length - 1 &&
                            lastPersonTotal > 0
                        ) {
                            persons[personIndex].total = persons[
                                personIndex
                            ].subtotal = lastPersonTotal

                            persons[personIndex].received = calculateRound(
                                lastPersonTotal,
                                2,
                                20
                            )
                        } else {
                            persons[personIndex].total = persons[
                                personIndex
                            ].subtotal = personTotal

                            persons[personIndex].received = calculateRound(
                                personTotal,
                                2,
                                20
                            )
                        }
                    }
                }
            )

            return persons
        },
        createOrUpdatePersons(): void {
            // If there are no persons yet, we need to create them
            if (!this.persons.length) {
                this.persons = this.splitEqually()
                return
            }

            // If there are more people selected then there are in the persons
            // add another Person object to the array.
            if (this.persons.length < this.personsCount) {
                this.persons.push({
                    identifier: generateRandomString(7),
                    set: false,
                    paid: false,
                    subtotal: 0,
                    total: 0,
                    discount: 0,
                    deposit: 0,
                    tip: 0,
                    received: 0,
                    productsCount: 0,
                    products: [],
                    pinPayment: {
                        reference: null,
                        is_successful: false,
                    },
                })
            } else if (this.persons.length > this.personsCount) {
                this.deletePersons()

                return
            }

            this.persons = this.splitEqually(true)
        },
        deletePersons(): void {
            let persons = cloneDeep(this.persons)
            const personsToDelete: number = persons.length - this.personsCount
            const indexesToDelete: Array<number> = []

            if (personsToDelete === 0) {
                return
            }

            // To make sure we start deleting from the 'bottom'
            persons = persons.reverse()

            persons.forEach(
                (person: SplitPaymentPerson, index: number): void => {
                    if (indexesToDelete.length === personsToDelete) {
                        return
                    }

                    // If person has set amount, skip for now
                    if (person.set) {
                        return
                    }

                    // If person has paid, skip
                    if (person.paid) {
                        return
                    }

                    indexesToDelete.push(index)
                }
            )

            // if there are not enough persons in the indexesToDelete array,
            // we need to delete ones that are set, but not paid.
            if (indexesToDelete.length < personsToDelete) {
                persons.forEach(
                    (person: SplitPaymentPerson, index: number): void => {
                        if (!person.paid) {
                            indexesToDelete.push(index)
                            return
                        }
                    }
                )
            }

            // Actually delete the persons
            indexesToDelete.forEach((index: number) => persons.splice(index, 1))

            // If persons is bigger then the personCount (due to people that have already paid)
            // We need to adjust the personsCount
            if (this.personsCount < persons.length) {
                this.personsCount = persons.length
            }

            // Go back to correct order
            persons = persons.reverse()

            this.persons = persons
            this.persons = this.splitEqually(true)
        },
        setBalance(balance: Balance) {
            this.balance = balance
        },
        onPaymentStepBack(): void {
            this.closeProductsModal()
            this.step = SplitPaymentStep.Type
            this.persons = []
        },
    },
})

const getInitialSplitPaymentStore = (): SplitPaymentState => {
    return {
        step: SplitPaymentStep.Number,
        splitType: SplitPaymentSplitType.Amount,
        personsCount: 2,
        persons: [],
        modalProducts: [],
        selectedPersonIndex: -1,
        orderId: 0,
        order: null,
        activePaymentIndex: -1,
        roundingDifference: 0,
        balance: {
            paid: { amount: 0, currency: "EUR" },
            remaining: { amount: 0, currency: "EUR" },
            total: { amount: 0, currency: "EUR" },
        },
        isPinPaymentLoading: false,
        loading: false,
    }
}
