import { defineStore } from "pinia"
import axios from "axios"
import { websocket } from "@/services/WebsocketService"
import { RiceCooker } from "@/riceCooker/riceCooker"
export interface offlineModeStoreState {
    isOffline: boolean
}
export const offlineModeStore = defineStore("offlineModeStore", {
    state: (): offlineModeStoreState => <offlineModeStoreState>({
            isOffline: false,
        } as offlineModeStoreState),
    actions: {
        async initiateOfflineMode() {
            await this.handleConnectionChange()
            window.addEventListener("online", this.handleConnectionChange)
            window.addEventListener("offline", this.handleConnectionChange)
        },
        async apiHealthCheck() {
            return axios
                .get(process.env.VUE_APP_API_HOST + "/health-check")
                .then(async (response) => {
                    return Promise.resolve(response)
                })
                .catch(async (error) => {
                    return Promise.reject(error)
                })
        },
        async handleConnectionChange() {
            const condition = navigator.onLine ? "online" : "offline"
            if (condition === "online") {
                await this.setOnline()
            } else {
                await this.setOffline()
            }
        },
        async setOnline() {
            if (this.isOffline) {
                console.log("[ONLINE/OFFLINE] ONLINE")
                if (RiceCooker.isPresent()) {
                    await window.riceCooker.setOnline()
                    axios.defaults.baseURL =
                        process.env.VUE_APP_API_HOST + "/v1"
                }
                this.isOffline = false
                websocket.start()
            }
        },
        async setOffline() {
            if (!this.isOffline) {
                console.log("[ONLINE/OFFLINE] OFFLINE")
                if (RiceCooker.isPresent()) {
                    await window.riceCooker.setOffline()
                    axios.defaults.baseURL =
                        await window.riceCooker.getBaseUrl()
                }
                this.isOffline = true
                websocket.stop()
            }
        },
    },
})
