import { defineStore } from "pinia"
import i18n from "@/i18n"
import { useOrdersStore } from "@/store/Orders"
import { OrderStatusType } from "@/interface/orders/Order"
const { t: translate } = i18n.global
export const useOrderModalStore = defineStore({
    id: "orderModal",
    state: () =>
        ({
            openedTab: "info",
            MapModalFilters: (
                localStorage.getItem("orderMapModalFilters") ||
                "init,pending,pending-ready,enroute,delivered,deliverers"
            ).split(","),
        } as {
            openedTab: string
            MapModalFilters: (OrderStatusType | "deliverers")[]
        }),
    getters: {
        menuOptions: () => {
            const ordersStore = useOrdersStore()
            return [
                {
                    id: "info",
                    label: translate("orders_modal_menu_info"),
                    component: "InfoTab",
                },
                {
                    id: "order",
                    label: `${translate("order")} (
                    ${
                        ordersStore.orderData(ordersStore.isShowingOrderId ?? 0)
                            ?.products.length ?? 0
                    }
                )`,
                    component: "OrderTab",
                },
                {
                    id: "remarks",
                    label: translate("remarks"),
                    component: "RemarksTab",
                },
                {
                    id: "route",
                    label: translate("route_planner"),
                    component: "RoutePlannerTab",
                    tabClass: "full-width",
                },
                {
                    id: "deliverers",
                    label: translate("deliverer"),
                    component: "DelivererTab",
                },
            ]
        },
    },
    actions: {
        updateStatusFilter(filters: (OrderStatusType | "deliverers")[]) {
            localStorage.setItem("orderMapModalFilters", filters.join(","))
            this.MapModalFilters = filters
        },
    },
})
