<template>
    <PopUpModal
        :show="true"
        @close="onClose"
        @submit="onSubmit"
        :submit-button-text="translate('setup_as_master')"
        :close-button-text="translate('setup_as_slave')"
        :loading="useUserStore().togglingMasterSlaveInPopup"
    >
        <div class="wrapper">
            <notification-component
                notification-type="warning"
                v-if="
                    useSettingsStore().settings.master_ob_id === null ||
                    useSettingsStore().settings.master_ob_id === undefined
                "
                class="master-active-notification"
            >
                <template #notificationLabel>{{
                    translate("no_master_active_title")
                }}</template>
                <template #notificationMessage
                    ><span v-html="translate('no_master_active_description')"
                /></template>
            </notification-component>
            <notification-component
                notification-type="warning"
                v-if="otherMasterIsActive"
                class="master-active-notification"
            >
                <template #notificationLabel>{{
                    translate("another_master_active_title")
                }}</template>
                <template #notificationMessage
                    ><span
                        v-html="translate('another_master_active_description')"
                /></template>
            </notification-component>
            <div class="title">{{ translate("setup_master_slave") }}</div>
            <div
                class="description"
                v-html="translate('master_slave_description')"
            ></div>
        </div>
    </PopUpModal>
</template>

<script lang="ts">
import { dataHydration } from "@/services/DataHydrationService"
import * as Sentry from "@sentry/vue"
import { AxiosResponse } from "axios"
import { computed, ComputedRef, defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { useSettingsStore } from "@/store/Settings"
import NotificationComponent from "@/ui-elements/NotificationComponent.vue"
import { useUserStore } from "@/store/User"

export default defineComponent({
    name: "SystemSetupMasterSlaveModal",
    methods: { useSettingsStore, useUserStore },
    components: {
        NotificationComponent,
        PopUpModal,
    },
    setup() {
        const { t: translate } = useI18n()
        const otherMasterIsActive: ComputedRef<boolean> = computed(
            () =>
                Boolean(
                    useSettingsStore().settings.master_ob_id &&
                        useSettingsStore().settings.master_ob_id !==
                            localStorage.getItem("ob_id")
                ) // Should be checked by parent, before showing modal, but just to play it safe
        )

        // Closing means confirming we want to stay slave
        const onClose = () => {
            useUserStore().confirmSlave()
            useUserStore().confirm_overwrite_master = false
            useUserStore().togglingMasterSlave = false
        }

        // Submitting means we want to become master
        const onSubmit = async () => {
            try {
                useUserStore().togglingMasterSlaveInPopup = true
                await useMasterSlave()
                    .setMaster(true)
                    .then(async (response: AxiosResponse) => {
                        if (response.data.data.status === "success") {
                            await dataHydration.hydrateModule("settings", true)
                        } else {
                            Sentry.captureException(
                                `Error setting master: ${
                                    response.data.data.message ??
                                    "unknown error"
                                }`
                            )
                        }
                    })
                    .finally(() => {
                        useUserStore().togglingMasterSlaveInPopup = false
                        useUserStore().confirm_overwrite_master = false
                        useUserStore().togglingMasterSlave = false
                    })
            } catch (e) {
                Sentry.captureException(e)
            }

            await dataHydration.hydrateModule("settings", true)
        }

        return {
            translate,
            onClose,
            onSubmit,
            otherMasterIsActive,
        }
    },
})
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: {
        bottom: 0;
    }

    .master-active-notification {
        margin: {
            bottom: $margin;
        }
    }

    .title {
        font-size: $font-size-h2;
        color: $secondary-color;
        font-weight: $font-weight-medium;
        margin-bottom: $margin-m;
    }

    .description {
        font-size: $font-size-base;
        color: $secondary-color;
    }
}
</style>
