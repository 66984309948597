import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-199ac424"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_component = _resolveComponent("notification-component")!
  const _component_PopUpModal = _resolveComponent("PopUpModal")!

  return (_openBlock(), _createBlock(_component_PopUpModal, {
    show: true,
    onClose: _ctx.onClose,
    onSubmit: _ctx.onSubmit,
    "submit-button-text": _ctx.translate('setup_as_master'),
    "close-button-text": _ctx.translate('setup_as_slave'),
    loading: _ctx.useUserStore().togglingMasterSlaveInPopup
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (
                    _ctx.useSettingsStore().settings.master_ob_id === null ||
                    _ctx.useSettingsStore().settings.master_ob_id === undefined
                )
          ? (_openBlock(), _createBlock(_component_notification_component, {
              key: 0,
              "notification-type": "warning",
              class: "master-active-notification"
            }, {
              notificationLabel: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("no_master_active_title")), 1)
              ]),
              notificationMessage: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.translate('no_master_active_description')
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.otherMasterIsActive)
          ? (_openBlock(), _createBlock(_component_notification_component, {
              key: 1,
              "notification-type": "warning",
              class: "master-active-notification"
            }, {
              notificationLabel: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("another_master_active_title")), 1)
              ]),
              notificationMessage: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: _ctx.translate('another_master_active_description')
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("setup_master_slave")), 1),
        _createElementVNode("div", {
          class: "description",
          innerHTML: _ctx.translate('master_slave_description')
        }, null, 8, _hoisted_5)
      ])
    ]),
    _: 1
  }, 8, ["onClose", "onSubmit", "submit-button-text", "close-button-text", "loading"]))
}