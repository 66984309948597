import { getOrCreateObId } from "@/utils/useMasterSlave"
import { defineStore } from "pinia"
import axios, { AxiosResponse } from "axios"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { User } from "@/interface/user/User"
import { websocket } from "@/services/WebsocketService"
import { gtmSendLoginEvent } from "@/utils/gtmSendEvent"
import * as Sentry from "@sentry/vue"
import {
    devGetFrontendVersion,
    devGetRiceCookerVersion,
} from "@/utils/devUtils"
import { updatesStore } from "@/store/Updates"
import { offlineModeStore } from "@/store/offlineMode"
import { multiLocationStore } from "@/store/MultiLocation"
import type { DeviceInformation } from "ricecooker-types/deviceinformation"

const defaultUser = (): User => ({
    id: -1,
    reseller_id: -1,
    website: "",
    email: "",
    phone: "",
    address: {
        company: "",
        contact: "",
        address: "",
        zipcode: "",
        city: "",
        state: null,
        country: "",
        phone: "",
        email: "",
    },
    billing_address: {
        company: "",
        contact: "",
        address: "",
        zipcode: "",
        city: "",
        state: null,
        country: "",
        phone: "",
        email: "",
        iban: "",
        iban_inc: null,
        bic: "",
        bic_inc: null,
        bic2: null,
        vat: "",
        coc: "",
    },
    coordinates: {
        coordinates: [0, 0],
        type: "Point",
    },
    domain: null,
    subdomain: "",
    created_at: null,
    vat_rates: [],
    updated_at: "",
    last_login_at: null,
    password_updated_at: null,
    preferred_domain: "",
    is_emergency_brake_active: false,
    logo_url: "",
    is_admin: false,
    is_impersonator_admin: false,
    is_read_only: false,
    admin_info: {
        online_orders: {
            counts: {
                online_paid: 0,
                all: 0,
            },
            commission: "0.00%",
            avg_amount: "€0.00",
        },
        google_my_business: {
            is_enabled: false,
            posts: 0,
        },
        ranking: {
            current_rank: 0,
            lowest_rank: 0,
        },
        abo_inv: 0,
        todos: [],
        invoices: [],
        startdate: "",
        account_manager: null,
        is_prizewheel_active: false,
        remarks: null,
        is_part_of: {
            type: "none",
            parent_ids: [],
            locations: "",
        },
    },
    start_of_working_day: "",
    end_of_working_day: "",
    uuid: "",
    status: "live",
})
export const useUserStore = defineStore({
    id: "user",
    state: () =>
        ({
            user: defaultUser(),
            admin_info_modal_open: false,
            waiterbuddy_platform: null,
            confirmedSlaveAt: localStorage.getItem("confirmedSlaveAt")
                ? parseInt(localStorage.getItem("confirmedSlaveAt")!, 10)
                : null,
            confirm_overwrite_master: false,
            togglingMasterSlave: false,
            togglingMasterSlaveInPopup: false,
        } as {
            user: User
            admin_info_modal_open: boolean
            waiterbuddy_platform: null | "ios" | "android"
            confirmedSlaveAt: number | null
            confirm_overwrite_master: boolean
            togglingMasterSlave: boolean
            togglingMasterSlaveInPopup: boolean
        }),
    getters: {
        isSet: (state): boolean => state.user.id >= -1,
        hasConfirmedSlaveRecently: (state): boolean => {
            if (!state.confirmedSlaveAt) {
                return false
            }

            const currentTimestamp: number = Math.floor(Date.now() / 1000)
            return currentTimestamp - state.confirmedSlaveAt < 24 * 60 * 60
        },
    },
    actions: {
        confirmSlave(): void {
            // Get the current Unix timestamp in seconds
            const unixTimestamp: number = Math.floor(Date.now() / 1000)
            localStorage.setItem("confirmedSlaveAt", unixTimestamp.toString())

            // Update the store state to trigger reactivity
            this.confirmedSlaveAt = unixTimestamp
        },
        removeSlaveConfirmation(): void {
            localStorage.removeItem("confirmedSlaveAt")
            this.confirmedSlaveAt = null
        },
        async fetchUser(): Promise<boolean> {
            try {
                const response: AxiosResponse<any> = await axios.get("/client")
                this.user = response.data.data
                this.user.admin_info = response.data.meta.client_admin_details
                // Activating multi kitchen or multi branches depends on the
                // client type
                multiLocationStore().setType(
                    response.data.meta.client_type,
                    this.user.id
                )
                this.user.is_impersonator_admin =
                    response.data.meta.is_impersonator_admin
                if (this.user.is_admin) {
                    this.admin_info_modal_open = true
                }
                // let RiceCooker know we're signed in
                await RiceCooker.login(this.user.id)
                // Start the websocket
                await websocket.start()
                // Check for updates
                await updatesStore().initUpdatesStore()
                // initiate offline detection
                await offlineModeStore().initiateOfflineMode()
                // Configure system details for Sentry and GTM
                let systemDetails = {
                    platform: RiceCooker.isPresent() ? "rice_cooker" : "web",
                    front_end_version: devGetFrontendVersion(),
                    rice_cooker_version: await devGetRiceCookerVersion(),
                    identifier: getOrCreateObId(),
                    serial_number: RiceCooker.isPresent()
                        ? (
                              await window.riceCooker.systemInformation()
                          ).serialNumber
                              .trim()
                              .split("\n")
                              .pop()
                        : null,
                    local_ip: RiceCooker.isPresent()
                        ? (await window.riceCooker.getIpAddress()) || null
                        : null,
                }

                const deviceInformation: DeviceInformation | undefined =
                    await RiceCooker.deviceInformation()

                systemDetails = {
                    ...systemDetails,
                    ...(typeof deviceInformation === "undefined"
                        ? {}
                        : deviceInformation),
                }

                // Send system details to Sentry
                Sentry.setUser({ id: this.user.id })
                Sentry.setContext("Foodticket Environment", systemDetails)

                // Send system details to our own back-end
                await axios.post("client/device-information", systemDetails)

                // Send system details to GTM
                gtmSendLoginEvent({
                    user_id: this.user.id,
                    ...systemDetails,
                })
                return true
            } catch (e) {
                Sentry.captureException(e)
                return false
            }
        },
        toggleAdminInfoModal() {
            if (this.user.is_admin) {
                this.admin_info_modal_open = !this.admin_info_modal_open
            }
        },
    },
})
