<template>
    <div class="tab-content deliverer-tab" :ref="scrollAreaRef()">
        <div
            class="deliverers"
            v-if="selectedDeliverer"
            :class="{
                separated:
                    delivererStore.activeDeliverers.length ||
                    delivererStore.inactiveDeliverers.length,
            }"
        >
            <DelivererTile
                :deliverer="selectedDeliverer"
                selected
                @click="selectDeliverer(null)"
            />
        </div>
        <div
            class="deliverers"
            :class="{ separated: delivererStore.inactiveDeliverers.length }"
        >
            <template
                v-for="deliverer of delivererStore.activeDeliverers"
                :key="deliverer.id"
            >
                <DelivererTile
                    v-if="deliverer.id !== order.deliverer_id"
                    :deliverer="deliverer"
                    @click="selectDeliverer(deliverer.id)"
                />
            </template>
        </div>
        <div class="deliverers">
            <template
                v-for="deliverer of delivererStore.inactiveDeliverers"
                :key="deliverer.id"
            >
                <DelivererTile
                    v-if="deliverer.id !== order.deliverer_id"
                    :deliverer="deliverer"
                    @click="selectDeliverer(deliverer.id)"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import DelivererTile from "@/pages/orders/modals/order/tabs/DelivererTile.vue"
import { Order, OrderStatusType } from "@/interface/orders/Order"
import { useDelivererStore } from "@/store/Deliverer"
import { updateOrderStatus } from "@/pages/orders/order/utils/updateStatus"
import axios from "axios"
import { useOrdersStore } from "@/store/Orders"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { useI18n } from "vue-i18n"

export default defineComponent({
    name: "DelivererTab",
    components: {
        DelivererTile,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const delivererStore = useDelivererStore()
        const selectedDeliverer = computed(() => {
            return delivererStore.deliverers.find(
                (deliverer) => deliverer.id === props.order.deliverer_id
            )
        })

        // @todo, code below calls endpoint for storing order with only the status (en route). This is not neeeded,
        // since it also calls the endpoint with status and deliverer id
        const selectDeliverer = async (delivererId: number) => {
            const ordersStore = useOrdersStore()
            const data = {
                deliverer_id: delivererId,
            } as { deliverer_id: number; status: OrderStatusType }

            if (props.order.delivery === "deliver" && delivererId) {
                const save = (newOrder: Order) =>
                    axios.patch(
                        "client/orders/" + ordersStore.isShowingOrderId,
                        newOrder
                    )

                const saveOrder = async (newOrder: any) => {
                    let success = false

                    save(newOrder)
                        .then((response) => {
                            useOrdersStore().saveOrder(response.data.data)
                            success = true
                        })
                        .catch(() => {
                            setToastNotification(
                                translate("something_went_wrong"),
                                translate("please_try_again"),
                                "danger"
                            )
                        })

                    return success
                }

                await updateOrderStatus(
                    "enroute",
                    {
                        ...props.order!,
                        ...data,
                    },
                    saveOrder
                ).then(() => {
                    data.status = "enroute"
                })
            }

            context.emit("update:order", data)
        }

        return {
            delivererStore,
            selectDeliverer,
            selectedDeliverer,
        }
    },
    emits: ["update:order"],
})
</script>

<style lang="scss" scoped>
.deliverer-tab {
    width: 100%;
    padding-right: $padding-m;
    display: flex;
    flex-direction: column;
    row-gap: $margin;
}

.deliverers {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: $padding;
    row-gap: $padding;
}

.separated {
    border-bottom: $normal-border;
    padding-bottom: $padding;
}
</style>
