import { useI18n } from "vue-i18n"
import i18n from "@/i18n"
import { Settings } from "luxon"
const localeFiles = require.context("@/lang", true, /^.*\.json$/)
const localeMap = {
    "ar-AR": "ar",
    "de-DE": "de",
    "en-GB": "en",
    "fr-FR": "fr",
    "it-IT": "it",
    "nl-NL": "nl",
    "zh-CN": "zh",
} as Record<string, string>

export const useLocalLocales = () => {
    const { t: translate } = useI18n()
    const getDefaultLocale = () => {
        const vueDefault = "nl"
        const localSetting = localStorage.getItem("localLocale")
        return localSetting ?? vueDefault
    }
    const getLocalLocales = () =>
        localeFiles
            .keys()
            .map(
                (localeString: any) =>
                    localeString.split(".json")[0].split("./")[1]
            )
            .concat("default")
            .map((localeKey: any) => {
                return {
                    value: localeKey,
                    label:
                        localeKey !== "default"
                            ? translate(
                                  "var_app.language_options." + localeKey,
                                  "",
                                  {
                                      locale: localeKey,
                                  }
                              )
                            : translate("default"),
                }
            })
    return {
        getDefaultLocale,
        getLocalLocales,
    }
}
export const setDirOnHTML = (dir: string) => {
    document.getElementsByTagName("html")[0].dir = dir
}
export const updateLocaleSettings = (locale: string) => {
    localStorage.setItem("localLocale", locale)
    setDirOnHTML(locale === "ar" ? "rtl" : "ltr")
    i18n.global.locale.value = locale
    Settings.defaultLocale = locale
}
