import type { Table } from "@/interface/table/Table"
import { defineStore } from "pinia"
import axios, { AxiosResponse } from "axios"
import qs from "qs"
import { UnwrapRef } from "vue"

export type TablesStore = {
    tables: Table[]
}

export const tableFields: string = [
    "id",
    "name",
    "order_id",
    "max_people",
    "priority",
    "qr_code",
    "order",
].join(",")

const orderFields = ["id", "total"].join(",")

const getQueryParams = () =>
    qs.stringify(
        {
            per_page: 100,
            include: "order",
            fields: {
                tables: tableFields,
                order: orderFields,
            },
        },
        { encode: false }
    )

const defaultTable: Table = {
    id: 0,
    order_id: null,
    name: "",
    max_people: 1,
    priority: 0,
    qr_code: "",
}

export const useTablesStore = defineStore({
    id: "tables",
    state: () =>
        ({
            tables: [],
        } as TablesStore),
    getters: {
        tableData:
            (state) =>
            (tableId: number): Table => {
                return (
                    state.tables.find((table) => table.id === tableId) ||
                    defaultTable
                )
            },
    },
    actions: {
        _log(
            message: string,
            isError: boolean = false,
            stackTrace: any = null
        ) {
            if (window.ob.hasDebugModule("TableStore")) {
                if (!isError) {
                    return console.log(`[TableStore] ${message}`)
                }

                return console.error(`[TableStore] ${message}`, stackTrace)
            }
        },
        async fetchTables(): Promise<boolean> {
            try {
                const params: string = getQueryParams()

                const response: AxiosResponse<any> = await axios.get(
                    `client/tables?${params}`
                )

                const tablesResponse: Table[] = response.data.data
                this.tables = tablesResponse.sort(
                    (prevTable: Table, nextTable: Table) =>
                        prevTable.priority - nextTable.priority
                )
                return true
            } catch (e) {
                return false
            }
        },
        async fetchObject(objectId: number): Promise<Table> {
            const params: string = getQueryParams()

            try {
                const response: AxiosResponse<any> = await axios.get(
                    `client/tables/${objectId}?${params}`
                )

                if (!response) {
                    this._log(`cannot fetch table with id #${objectId}`)
                }

                return response.data.data as Table
            } catch (e) {
                this._log(`cannot fetch table with id #${objectId}`, true, e)
                return defaultTable
            }
        },
        async objectCreated(objectId: number): Promise<boolean> {
            const table: Table = await this.fetchObject(objectId)
            const existingObject: UnwrapRef<Table> | undefined =
                this.tables.find(
                    (table: Table): boolean => table.id == objectId
                )

            if (existingObject) {
                await this.objectUpdated(objectId)
                this._log(
                    `Table with id #${objectId} was sent as created, but already exists. Updated the existing table instead`
                )
                return true
            }

            this.tables.push(table)

            return true
        },
        async objectUpdated(objectId: number): Promise<void> {
            const table: Table = await this.fetchObject(objectId)
            const existingObject: UnwrapRef<Table> | undefined =
                this.tables.find(
                    (table: Table): boolean => table.id == objectId
                )

            if (existingObject) {
                this.tables[this.tables.indexOf(existingObject)] = table
            }
        },
        objectCleared(objectId: number): void {
            const existingObject: UnwrapRef<Table> | undefined =
                this.tables.find(
                    (table: Table): boolean => table.id == objectId
                )

            if (existingObject) {
                this.tables[this.tables.indexOf(existingObject)].order_id = null
            }
        },
        saveTable(table: Table): void {
            const existingTable: number = this.tables.findIndex(
                (item: UnwrapRef<Table>): boolean => item.id === table.id
            )
            if (existingTable >= 0) {
                this.tables[existingTable] = table
            } else {
                this.tables.push(table)
            }
        },
        async deleteTable(tableId: number): Promise<void> {
            const existingTable: number = this.tables.findIndex(
                (item: UnwrapRef<Table>): boolean => item.id === tableId
            )
            if (existingTable >= 0) {
                this.tables.splice(existingTable, 1)
            }
        },
    },
})
